import { JsonRpcSigner, Provider } from "@ethersproject/providers";
import { captureException } from "@sentry/react";
import * as Sentry from "@sentry/react";
import { BigNumber, ethers } from "ethers";

import { SupportedCurrencies } from "@usecyan/shared/types/currency";

import { factories as f } from "@cyanco/contract";
import { BatchRead } from "@cyanco/contract/abis/CyanBatchReader";

import { IPawn, IPawnStatus } from "@/components/Account/pawn.types";
import { IPlanBe } from "@/components/Account/plan.types";
import { IBNPL, IBNPLStatus } from "@/components/Bnpl/bnpl.types";
import { VaultContractAbiNames } from "@/components/Vault/types";
import { isProd, nonNativeSupportedCurrenciesData } from "@/config";
import { SupportedChainId } from "@/constants/chains";
import { getBatchReaderAddressFromChainId } from "@/constants/contracts";
import { IPlanStatus } from "@/constants/plans";
import { INftType } from "@/types";

import { bigNumToFloat, getEnvOrThrow, getJsonRpcProvider } from ".";
import { IBatchReaderData, IBendDaoLoan } from "./types";

export const getSignInSignature = async (wallet: string, signer: JsonRpcSigner): Promise<string> => {
  // WARN: Do not touch `message` unless you know what you're doing!
  const message = `
Welcome to Cyan!

Click to sign in and accept the Cyan Terms of Service: https://docs.usecyan.com/docs/terms-of-service

This request will not trigger a blockchain transaction or cost any gas fees.

Wallet address: ${wallet}
`;
  return await signer.signMessage(message);
};

export const getTokenPriceFromVaultContract = async (
  abiName: string,
  contractAddress: string,
  provider: Provider,
  decimals = 18,
) => {
  const oneCyanVaultToken = Math.pow(10, decimals).toString();
  try {
    switch (abiName) {
      case VaultContractAbiNames.ApeCoinVaultV1: {
        const reader = f.CyanApeCoinVaultV1Factory.connect(contractAddress, provider);
        return (await reader.calculateCurrencyByToken(oneCyanVaultToken)).toString();
      }
      case VaultContractAbiNames.CyanVaultV2:
      case VaultContractAbiNames.CyanVaultV2_1: {
        const reader = f.CyanVaultV2Factory.connect(contractAddress, provider);
        return (await reader.calculateCurrencyByToken(oneCyanVaultToken)).toString();
      }
      default:
        throw new Error(`Invalid vault abi ${abiName}`);
    }
  } catch (error) {
    return "0";
  }
};

export const getCyanWalletAddress = async ({
  provider,
  mainWallet,
}: {
  provider: Provider;
  mainWallet: string;
}): Promise<string | null> => {
  const CYAN_FACTORY_ADDRESS = getEnvOrThrow("CYAN_FACTORY_ADDRESS");
  const factory = f.FactoryFactory.connect(CYAN_FACTORY_ADDRESS, provider);
  const cyanWalletAddress = await factory.getOwnerWallet(mainWallet);
  if (cyanWalletAddress.toLowerCase() === ethers.constants.AddressZero.toLowerCase()) {
    return null;
  }
  return cyanWalletAddress;
};

export const getAccountBalanceOfErc20 = async ({
  currencyAddress,
  provider,
  mainWallet,
  chainId,
}: {
  currencyAddress: string;
  provider: Provider;
  mainWallet: string;
  chainId: SupportedChainId;
}) => {
  const CYAN_FACTORY_ADDRESS = getEnvOrThrow("CYAN_FACTORY_ADDRESS");
  const batchReaderContractAddress = getBatchReaderAddressFromChainId(chainId);
  const factory = f.FactoryFactory.connect(CYAN_FACTORY_ADDRESS, provider);
  const cyanWallet = await factory.getOwnerWallet(mainWallet);
  let mainWalletBalance = BigNumber.from(0);
  let cyanWalletBalance = BigNumber.from(0);
  const iErc20 = f.SampleERC20TokenFactory.createInterface();
  const iBatchRead = f.CyanBatchReaderFactory.createInterface();
  const transactionData: IBatchReaderData[] = [];
  const isNativeCurrency = currencyAddress.toLowerCase() === ethers.constants.AddressZero.toLowerCase();
  // encode batch read data
  if (isNativeCurrency) {
    transactionData.push({
      contractAddress: batchReaderContractAddress,
      functionName: "balance",
      params: [mainWallet],
      interface: iBatchRead,
    });
    if (cyanWallet.toLowerCase() !== ethers.constants.AddressZero.toLowerCase()) {
      transactionData.push({
        contractAddress: batchReaderContractAddress,
        functionName: "balance",
        params: [cyanWallet],
        interface: iBatchRead,
      });
    }
  } else {
    transactionData.push({
      functionName: "balanceOf",
      contractAddress: currencyAddress,
      params: [mainWallet],
      interface: iErc20,
    });
    if (cyanWallet.toLowerCase() !== ethers.constants.AddressZero.toLowerCase()) {
      transactionData.push({
        functionName: "balanceOf",
        contractAddress: currencyAddress,
        params: [cyanWallet],
        interface: iErc20,
      });
    }
  }
  const batchResult = await executeBatchRead(chainId, provider, transactionData);
  // decode batch balance result
  mainWalletBalance = batchResult[0][0];
  if (cyanWallet.toLowerCase() !== ethers.constants.AddressZero.toLowerCase()) {
    cyanWalletBalance = batchResult[1][0];
  }

  return {
    mainWalletBalance,
    cyanWalletBalance,
  };
};

export const getNftTransferFnDataForCyanWallet = ({
  tokenType,
  tokenId,
  collectionAddress,
  from,
  to,
}: {
  tokenType?: INftType;
  tokenId: string;
  collectionAddress: string;
  from: string;
  to: string;
}) => {
  let encodedFnDataTransfer;
  if (tokenType === INftType.ERC1155) {
    const contractIFace = f.SampleERC1155TokenFactory.createInterface();
    encodedFnDataTransfer = contractIFace.encodeFunctionData("safeTransferFrom", [from, to, tokenId, 1, []]);
  } else if (tokenType === INftType.CryptoPunks) {
    const contractIFace = f.SampleCryptoPunksFactory.createInterface();
    encodedFnDataTransfer = contractIFace.encodeFunctionData("transferPunk", [to, tokenId]);
  } else {
    const contractIFace = f.SampleFactory.createInterface();
    encodedFnDataTransfer = contractIFace.encodeFunctionData("safeTransferFrom(address,address,uint256)", [
      from,
      to,
      tokenId,
    ]);
  }
  const encodedFnDataTransferFormatted = [collectionAddress, 0, encodedFnDataTransfer];
  return encodedFnDataTransferFormatted;
};

export const getVaultBalance = async ({ vaultAddress, provider }: { vaultAddress: string; provider: Provider }) => {
  const vaultContractWriter = f.CyanVaultV2Factory.connect(vaultAddress, provider);
  return await vaultContractWriter.getMaxWithdrawableAmount();
};

export const getBendDaoLoanAmount = async ({
  provider,
  collection,
  tokenId,
  chainId,
}: {
  collection: string;
  tokenId: string;
  provider: Provider;
  chainId: number;
}): Promise<IBendDaoLoan> => {
  const abi = [
    "function getLendPool() external view returns (address)",
    "function getLendPoolLoan() external view returns (address)",
    "function getCollateralLoanId(address, uint256) external view returns (uint256)",
    "function getLoanReserveBorrowAmount(uint256) external view returns (address, uint256)",
    "function getLoan(uint256) external view returns (uint256 loanId, uint8 state, address borrower, address nftAsset, uint256 nftTokenId, address reserveAsset, uint256 scaledAmount, uint256 bidStartTimestamp, address bidderAddress, uint256 bidPrice, uint256 bidBorrowAmount, address firstBidderAddress)",
  ];
  const bendDaoAddressProviderAddr = isProd
    ? "0x24451f47caf13b24f4b5034e1df6c0e401ec0e46"
    : "0x1cba0A3e18be7f210713c9AC9FE17955359cC99B";

  const bendDaoAddressProvider = new ethers.Contract(bendDaoAddressProviderAddr, abi, provider);
  const lendPoolLoanAddr = await bendDaoAddressProvider.getLendPoolLoan();
  const lendPoolLoan = new ethers.Contract(lendPoolLoanAddr, abi, provider);
  const loanId = await lendPoolLoan.getCollateralLoanId(collection, tokenId);
  const [, loanAmount] = await lendPoolLoan.getLoanReserveBorrowAmount(bigNumToFloat(loanId, 0));
  const [, , , , , currencyAddress] = await lendPoolLoan.getLoan(bigNumToFloat(loanId, 0));

  return {
    loanAmount,
    loanCurrencyAddress:
      currencyAddress.toLowerCase() ===
      nonNativeSupportedCurrenciesData[chainId][SupportedCurrencies.WETH].address.toLowerCase()
        ? ethers.constants.AddressZero.toLowerCase()
        : currencyAddress,
  };
};

export const executeBatchRead = async (chainId: number, provider: Provider, batchDatas: IBatchReaderData[]) => {
  const SupportedChainsByEnv = isProd
    ? [SupportedChainId.MAINNET, SupportedChainId.POLYGON, SupportedChainId.BLAST]
    : [SupportedChainId.SEPOLIA, SupportedChainId.BLAST_SEPOLIA, SupportedChainId.CURTIS];
  if (batchDatas.length === 0 || !SupportedChainsByEnv.includes(chainId)) {
    return [];
  }
  try {
    const batchReaderAddress = getBatchReaderAddressFromChainId(chainId);
    const batchReaderWriter = f.CyanBatchReaderFactory.connect(batchReaderAddress, provider);

    const batchReadDatas: BatchRead.ReadStruct[] = [];

    batchDatas.forEach(({ interface: iface, params, functionName, contractAddress }) => {
      const encodedFunction = iface.encodeFunctionData(functionName, params);
      batchReadDatas.push({
        to: contractAddress,
        data: encodedFunction,
      });
    });

    const batchResult = await batchReaderWriter.batchRead(batchReadDatas);

    const results = [];
    for (let i = 0; i < batchResult.length; i++) {
      const decodedResult = batchDatas[i].interface.decodeFunctionResult(batchDatas[i].functionName, batchResult[i]);
      results.push(decodedResult);
    }

    return results;
  } catch (error) {
    console.error("Error executing batch read:", error);
    captureException("Error executing batch read:", { extra: { error } });
    throw error;
  }
};
export const getCyanWalletLockedDate = async ({
  cyanWallet,
  vaultAddress,
  provider,
}: {
  cyanWallet: string;
  vaultAddress: string;
  provider: Provider;
}) => {
  const vaultContractReader = f.CyanVaultV2Factory.connect(vaultAddress, provider);
  return await vaultContractReader.withdrawLocked(cyanWallet);
};

export const getLockTermAndTokenPriceOfVaults = async (args: {
  vaults: {
    abiName: string;
    contractAddress: string;
    decimals: number;
  }[];
  provider: Provider;
  chainId: SupportedChainId;
}) => {
  try {
    const { vaults, provider, chainId } = args;
    const batchReadData: IBatchReaderData[] = [];
    const iCyanVaultV2 = f.CyanVaultV2Factory.createInterface();
    const iApeVaultV1 = f.CyanApeCoinVaultV1Factory.createInterface();

    for (const vault of vaults) {
      const oneCyanVaultToken = Math.pow(10, vault.decimals).toString();
      if (
        vault.abiName === VaultContractAbiNames.CyanVaultV2 ||
        vault.abiName === VaultContractAbiNames.CyanVaultV2_1
      ) {
        const contract = {
          contractAddress: vault.contractAddress,
          interface: iCyanVaultV2,
        };
        batchReadData.push(
          {
            ...contract,
            functionName: iCyanVaultV2.getFunction("calculateCurrencyByToken").name,
            params: [oneCyanVaultToken],
          },
          {
            ...contract,
            functionName: iCyanVaultV2.getFunction("_withdrawLockTerm").name,
            params: [],
          },
        );
      } else if (vault.abiName === VaultContractAbiNames.ApeCoinVaultV1) {
        const contract = {
          contractAddress: vault.contractAddress,
          interface: iApeVaultV1,
        };
        batchReadData.push({
          ...contract,
          functionName: iApeVaultV1.getFunction("calculateCurrencyByToken").name,
          params: [oneCyanVaultToken],
        });
      }
    }
    const batchResult = await executeBatchRead(chainId, provider, batchReadData);
    let batchResultIndex = 0;
    const contractData = vaults.map(vault => {
      if (
        vault.abiName === VaultContractAbiNames.CyanVaultV2 ||
        vault.abiName === VaultContractAbiNames.CyanVaultV2_1
      ) {
        const tokenPriceIndex = batchResultIndex;
        const withdrawLockTermIndex = batchResultIndex + 1;
        batchResultIndex += 2;
        return {
          withdrawLockTerm: BigNumber.from(batchResult[withdrawLockTermIndex][0]),
          tokenPrice: BigNumber.from(batchResult[tokenPriceIndex][0]),
        };
      } else if (vault.abiName === VaultContractAbiNames.ApeCoinVaultV1) {
        const tokenPriceIndex = batchResultIndex;
        batchResultIndex += 1;
        return {
          tokenPrice: BigNumber.from(batchResult[tokenPriceIndex][0]),
          withdrawLockTerm: BigNumber.from(0),
        };
      } else {
        throw new Error(`Invalid vault abi ${vault.abiName}`);
      }
    });
    return contractData;
  } catch (e) {
    captureException("Error getting lock term and token price of vaults", { extra: { error: e } });
    return [];
  }
};

export const readPlansOnChain = async (plans: IPlanBe[]): Promise<{ pawns: IPawn[]; bnpls: IBNPL[] }> => {
  const pawns: IPawn[] = [];
  const bnpls: IBNPL[] = [];
  if (plans.length == 0) {
    return { pawns, bnpls };
  }
  const v2Plans = plans.filter(plan => plan.paymentPlan.abiName === "PaymentPlanV2");
  const v1CompletedPlans = plans.filter(
    plan => plan.paymentPlan.abiName === "PaymentPlanV1" && plan.status === IPlanStatus.Completed,
  );
  for (const plan of v1CompletedPlans) {
    const totalPaid = plan.payments.reduce((acc, cur) => acc.add(cur.amount), BigNumber.from(0));
    // 1% service fee
    const serviceFeeAmount = totalPaid.div(100).toString();
    const amountWithoutServiceFee = totalPaid.sub(serviceFeeAmount);
    const totalNumOfPayments = plan.payments.length;
    if (plan.planType === "Pawn" && plan.status === IPlanStatus.Completed) {
      const appraisalValue = amountWithoutServiceFee.mul(100_00).div(plan.loanedPercent);
      pawns.push({
        ...plan,
        planType: "Pawn" as const,
        status: plan.status as IPawnStatus,
        pawnedAmount: amountWithoutServiceFee.toString(),
        appraisalValue: appraisalValue.toString(),
        interestFee: BigNumber.from(0).toString(),
        interestRate: 0,
        serviceFeeAmount,
        serviceFeePercent: 100,
        totalNumOfPayments,
      });
    } else if (plan.planType === "BNPL") {
      const downpaymentPercent = 100_00 - plan.loanedPercent;
      const downpaymentAmount = amountWithoutServiceFee.mul(downpaymentPercent).div(plan.loanedPercent);
      bnpls.push({
        ...plan,
        planType: "BNPL" as const,
        status: plan.status as IBNPLStatus,
        serviceFeeAmount,
        serviceFeePercent: 100,
        totalNumOfPayments: totalNumOfPayments,
        interestFee: BigNumber.from(0).toString(),
        interestRate: 0,
        price: downpaymentAmount.add(amountWithoutServiceFee).toString(),
        downpaymentPercent,
        downpaymentAmount: downpaymentAmount.toString(),
      });
    }
  }
  const v2GroupedByChains = v2Plans.reduce<{
    [key: string]: IPlanBe[];
  }>((acc, cur) => {
    const { chainId } = cur.paymentPlan;
    acc[chainId] = acc[chainId] || [];
    acc[chainId].push(cur);
    return acc;
  }, {});
  for (const [chainId, groupedPlans] of Object.entries(v2GroupedByChains)) {
    const provider = getJsonRpcProvider(Number(chainId));
    if (provider) {
      const iPaymentPlanV2 = f.PaymentPlanV2Factory.createInterface();
      const batchData = groupedPlans.map(plan => ({
        interface: iPaymentPlanV2,
        params: [plan.planId],
        functionName: "paymentPlan",
        contractAddress: plan.paymentPlan.address,
      }));
      try {
        const batchResult = await executeBatchRead(Number(chainId), provider, batchData);
        groupedPlans.forEach((plan, index) => {
          const planData = batchResult[index].plan;
          const { amount, interestRate, serviceFeeRate, totalNumberOfPayments, downPaymentPercent } = planData;
          const serviceFeePercent = planData.serviceFeeRate;
          const serviceFeeAmount = amount.mul(serviceFeeRate).div(100_00);
          const totalNumOfPayments = totalNumberOfPayments;
          const singleServiceFeeAmount = serviceFeeAmount.eq(0) ? 0 : serviceFeeAmount.div(totalNumOfPayments);
          if (plan.planType === "Pawn") {
            pawns.push({
              ...plan,
              planType: "Pawn" as const,
              status: plan.status as IPawnStatus,
              pawnedAmount: amount.toString(),
              appraisalValue: amount.mul(100_00).div(plan.loanedPercent).toString(),
              totalNumOfPayments,
              interestFee: amount.mul(interestRate).div(100_00).toString(),
              interestRate,
              serviceFeePercent,
              serviceFeeAmount: serviceFeeAmount.toString(),
            });
          } else {
            const downpaymentAmount = amount
              .mul(downPaymentPercent ?? 2500)
              .div(100_00)
              .add(singleServiceFeeAmount);
            bnpls.push({
              ...plan,
              planType: "BNPL" as const,
              status: plan.status as IBNPLStatus,
              totalNumOfPayments,
              interestFee: amount.sub(downpaymentAmount).mul(interestRate).div(100_00).toString(),
              interestRate,
              serviceFeePercent,
              serviceFeeAmount: serviceFeeAmount.toString(),
              price: amount,
              downpaymentPercent: downPaymentPercent,
              downpaymentAmount: downpaymentAmount.toString(),
            });
          }
        });
      } catch (e) {
        Sentry.captureException(e);
        return { pawns, bnpls };
      }
    }
  }
  return { pawns, bnpls };
};
